
import { defineComponent, computed, onMounted, ref, PropType } from "vue";
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import { useAuthStore } from "@/store/useAuthStore";
import ActivityRoomPostService from "@/core/services/ActivityRoomPostService";
import { useRoute } from "vue-router";
import UserRoleService from "@/core/services/UserRoleService";
import { IActivityPostLike } from "@/core/data/activityPostLike";
import { IActivityRoomComment } from "@/core/data/activityRoomComment";
import ActivityRoomCommentService from "@/core/services/ActivityRoomCommentService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "showcases-card",
  setup(props) {
    const route = useRoute();
    const currentComment = ref("");
    const currentPost = ref<IActivityRoomPost>();
    const authStore = useAuthStore();
    const isTeacher = ref<boolean>(false);
    const isLike = ref<boolean>(false);
    const activityPostLikes = ref<Array<IActivityPostLike>>([]);
    const postAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activity-room-posts/attachments/"
    );

    const currentUser = computed(() => {
      return authStore.currentUser;
    });

    const initPosts = () => {
      ActivityRoomPostService.getPostById(route.params.id).then((response) => {
        currentPost.value = response.data;
      });
    };

    const likePost = () => {
      isLike.value = !isLike.value;

      if (isLike.value) {
        let postLike = ref<IActivityPostLike>({
          id: null,
          post: currentPost.value,
          user: authStore.currentUser!,
        });
        ActivityRoomPostService.likePost(postLike.value).then((response) => {
          initLikes();
        });
      } else {
        ActivityRoomPostService.unlikePost(currentPost.value!.id).then(
          (response) => {
            initLikes();
          }
        );
      }
    };

    const initLikes = () => {
      ActivityRoomPostService.getPostLikeByPostId(route.params.id).then(
        (response) => {
          activityPostLikes.value = response.data;

          let activityPostLike = activityPostLikes.value.find(
            (obj) => obj.user?.id === authStore.currentUser?.id
          );

          isLike.value = activityPostLike != undefined ? true : false;
        }
      );
    };

    const acceptPost = () => {
      if (currentPost.value!.is_accept) {
        ActivityRoomPostService.unAcceptPost(route.params.id).then(
          (response) => {
            initPosts();
          }
        );
      } else {
        ActivityRoomPostService.acceptPost(route.params.id).then((response) => {
          initPosts();
        });
      }
    };

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    const isCommentOwner = (comment) => {
      return comment.user?.id == authStore.currentUser?.id ? true : false;
    };

    const addComment = () => {
      if (currentComment.value != "" && currentComment.value != undefined) {
        let comment = ref<IActivityRoomComment>({
          id: null,
          post: currentPost.value!,
          user: authStore.currentUser!,
          comment: currentComment.value,
          created_at: new Date(),
        });

        ActivityRoomCommentService.createComment(comment.value)
          .then((response) => {
            currentComment.value = "";
            initPosts();
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    const deleteComment = (comment) => {
      Swal.fire({
        title: `Would you like to delete this comment?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          ActivityRoomCommentService.deleteComment(comment?.id)
            .then(() => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              initPosts();
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };

    const imgList = ref([
      "150-1.jpg",
      "150-2.jpg",
      "150-3.jpg",
      "150-1.jpg",
      "150-2.jpg",
      "150-3.jpg",
      "150-3.jpg",
    ]);

    const imgIndex = ref({
      index: 1,
      max: 6,
    });
    const nextImg = () => {
      if (imgIndex.value.index + 1 > imgIndex.value.max) {
        imgIndex.value.index = 1;
      } else {
        imgIndex.value.index++;
      }
    };
    const prevImg = () => {
      if (imgIndex.value.index - 1 < 1) {
        imgIndex.value.index = imgIndex.value.max;
      } else {
        imgIndex.value.index--;
      }
    };
    onMounted(() => {
      initPosts();
      initLikes();
      getTeacher();
    });
    return {
      postAttachmentbaseURL,
      currentComment,
      imgIndex,
      nextImg,
      prevImg,
      imgList,
      currentUser,
      currentPost,
      acceptPost,
      isTeacher,
      isLike,
      activityPostLikes,
      initPosts,
      likePost,
      addComment,
      deleteComment,
      isCommentOwner,
    };
  },
});
