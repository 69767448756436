import { AxiosResponse } from "axios";
import ApiService from "./ApiService";
import { IActivityPostLike } from "../data/activityPostLike";

const API_URL = "activity-room-posts";

class ActivityRoomPostService {
  public static getPostById(postId): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/" + postId);
  }

  public static getPostByActivityRoom(activityRoomId): Promise<AxiosResponse> {
    return ApiService.get(
      API_URL + "/search/by-activity-room/" + activityRoomId
    );
  }

  public static createPost(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL, formData);
  }

  public static updatePost(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/edit", formData);
  }

  public static deletePost(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }

  public static getPostLikeByPostId(id): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/like/search/by-post/" + id);
  }

  public static likePost(postLike: IActivityPostLike): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/like", postLike);
  }

  public static unlikePost(postId): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/unlike/" + postId);
  }

  public static acceptPost(id): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/accept", { id: id });
  }

  public static unAcceptPost(id): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/unaccept", { id: id });
  }

  public static getLatestPost(): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/search/latest");
  }
}

export default ActivityRoomPostService;
