import { AxiosResponse } from "axios";
import ApiService from "./ApiService";
import { IActivityRoomComment } from "../data/activityRoomComment";

const API_URL = "activity-comments";

class ActivityRoomCommentService {
  public static createComment(
    comment: IActivityRoomComment
  ): Promise<AxiosResponse> {
    return ApiService.post(API_URL, comment);
  }

  public static deleteComment(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }
}

export default ActivityRoomCommentService;
